<template>
  <div class="navigation">
    <div class="navigation__menu">
      <el-menu @select="setRoute" v-if="isUserAdministrator">
        <el-menu-item index="AdministrationAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label"> Dashboard </span>
          </template>
        </el-menu-item>
        <el-sub-menu index="AdministrationAdministratorShopIndex">
          <template #title>
            <span class="icon">
              <ShoppingBag />
            </span>
            <span class="label"> Shop </span>
          </template>
          <el-menu-item index="AdministrationAdministratorShopOrdersIndex">
            <template #title>
              <span class="label"> Orders </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationAdministratorShopArtistsIndex">
            <template #title>
              <span class="label"> Artists </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationAdministratorShopEventsIndex">
            <template #title>
              <span class="label"> Events </span>
            </template>
          </el-menu-item>
          <!--          <el-menu-item index="AdministrationAdministratorShopMerchIndex">-->
          <!--            <template #title>-->
          <!--            <span class="label">-->
          <!--              Merch-->
          <!--            </span>-->
          <!--            </template>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="AdministrationAdministratorShopLibraryIndex">
            <template #title>
              <span class="label"> Library </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationAdministratorApprovalsIndex">
          <template #title>
            <span class="icon">
              <CircleCheck />
            </span>
            <span class="label"> Approvals </span>
          </template>
          <el-menu-item index="AdministrationAdministratorApprovalsArtistsIndex">
            <template #title>
              <span class="label"> Artists </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationAdministratorApprovalsEventsIndex">
            <template #title>
              <span class="label"> Events </span>
            </template>
          </el-menu-item>
          <!--          <el-menu-item index="AdministrationAdministratorApprovalsMerchIndex">-->
          <!--            <template #title>-->
          <!--              <span class="label">-->
          <!--                Merch-->
          <!--              </span>-->
          <!--            </template>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="AdministrationAdministratorApprovalsLibraryIndex">
            <template #title>
              <span class="label"> Library </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationAdministratorNewslettersIndex">
          <template #title>
            <span class="icon">
              <Message />
            </span>
            <span class="label"> Newsletters </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorVideosIndex">
          <template #title>
            <span class="icon">
              <VideoPlay />
            </span>
            <span class="label"> Videos </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserPartner">
        <el-menu-item index="AdministrationPartnerDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label"> Dashboard </span>
          </template>
        </el-menu-item>
        <el-sub-menu index="AdministrationPartnerShopIndex">
          <template #title>
            <span class="icon">
              <ShoppingBag />
            </span>
            <span class="label"> Shop </span>
          </template>
          <el-menu-item index="AdministrationPartnerShopArtistsIndex">
            <template #title>
              <span class="label"> Artists </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationPartnerShopEventsIndex">
            <template #title>
              <span class="label"> Events </span>
            </template>
          </el-menu-item>
          <!--          <el-menu-item index="AdministrationPartnerShopMerchIndex">-->
          <!--            <template #title>-->
          <!--              <span class="label">-->
          <!--                Merch-->
          <!--              </span>-->
          <!--            </template>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="AdministrationPartnerShopLibraryIndex">
            <template #title>
              <span class="label"> Library </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationPartnerApprovalsIndex">
          <template #title>
            <span class="icon">
              <CircleCheck />
            </span>
            <span class="label"> Approvals </span>
          </template>
          <el-menu-item index="AdministrationPartnerApprovalsArtistsIndex">
            <template #title>
              <span class="label"> Artists </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationPartnerApprovalsEventsIndex">
            <template #title>
              <span class="label"> Events </span>
            </template>
          </el-menu-item>
          <!--          <el-menu-item index="AdministrationPartnerApprovalsMerchIndex">-->
          <!--            <template #title>-->
          <!--              <span class="label">-->
          <!--                Merch-->
          <!--              </span>-->
          <!--            </template>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="AdministrationPartnerApprovalsLibraryIndex">
            <template #title>
              <span class="label"> Library </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserSuperAdministrator">
        <el-menu-item index="AdministrationSuperAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label"> Dashboard </span>
          </template>
        </el-menu-item>
        <el-sub-menu index="AdministrationSuperAdministratorShopIndex">
          <template #title>
            <span class="icon">
              <ShoppingBag />
            </span>
            <span class="label"> Shop </span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorShopOrdersIndex">
            <template #title>
              <span class="label"> Orders </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorShopArtistsIndex">
            <template #title>
              <span class="label"> Artists </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorShopEventsIndex">
            <template #title>
              <span class="label"> Events </span>
            </template>
          </el-menu-item>
          <!--          <el-menu-item index="AdministrationSuperAdministratorShopMerchIndex">-->
          <!--            <template #title>-->
          <!--            <span class="label">-->
          <!--              Merch-->
          <!--            </span>-->
          <!--            </template>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="AdministrationSuperAdministratorShopLibraryIndex">
            <template #title>
              <span class="label"> Library </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorShopCouponsIndex">
            <template #title>
              <span class="label"> Coupons </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationSuperAdministratorApprovalsIndex">
          <template #title>
            <span class="icon">
              <CircleCheck />
            </span>
            <span class="label"> Approvals </span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorApprovalsArtistsIndex">
            <template #title>
              <span class="label"> Artists </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorApprovalsEventsIndex">
            <template #title>
              <span class="label"> Events </span>
            </template>
          </el-menu-item>
          <!--          <el-menu-item index="AdministrationSuperAdministratorApprovalsMerchIndex">-->
          <!--            <template #title>-->
          <!--              <span class="label">-->
          <!--                Merch-->
          <!--              </span>-->
          <!--            </template>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="AdministrationSuperAdministratorApprovalsLibraryIndex">
            <template #title>
              <span class="label"> Library </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationSuperAdministratorSettingsIndex">
          <template #title>
            <span class="icon">
              <Setting />
            </span>
            <span class="label"> Settings </span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorSettingsCurrenciesIndex">
            <template #title>
              <span class="label"> Currencies </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorSettingsProductTypesIndex">
            <template #title>
              <span class="label"> Product Types </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorSettingsProductCategoriesIndex">
            <template #title>
              <span class="label"> Product Categories </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationSuperAdministratorUsersIndex">
          <template #title>
            <span class="icon">
              <User />
            </span>
            <span class="label"> Users </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorNewslettersIndex">
          <template #title>
            <span class="icon">
              <Message />
            </span>
            <span class="label"> Newsletters </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorVideosIndex">
          <template #title>
            <span class="icon">
              <VideoPlay />
            </span>
            <span class="label"> Videos </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorPromotionsIndex">
          <template #title>
            <span class="icon">
              <Aim />
            </span>
            <span class="label"> Promotions </span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { Options, Vue } from "vue-class-component";

// Store
import store from "@/store";

// Components
import { ElMenu, ElMenuItemGroup, ElMenuItem, ElSubMenu } from "element-plus";
import { Aim, CircleCheck, House, Message, Setting, ShoppingBag, User, VideoPlay } from "@element-plus/icons-vue";

@Options({
  components: {
    ElMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElSubMenu,
    Aim,
    CircleCheck,
    House,
    Message,
    Setting,
    ShoppingBag,
    User,
    VideoPlay,
  },
})
export default class Navigation extends Vue {
  get isUserPartner(): boolean {
    return store.getters.getUser.role === "partner";
  }

  get isUserAdministrator(): boolean {
    return store.getters.getUser.role === "administrator";
  }

  get isUserSuperAdministrator(): boolean {
    return store.getters.getUser.role === "super-administrator";
  }

  setRoute(routeName: string): void {
    this.$router.push({
      name: routeName,
    });
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/core/_variables.scss";
@import "~@/assets/scss/mixins/_breakpoints.scss";

.navigation {
  width: 200px;
  min-width: 200px;
  min-height: calc(100vh - 134px);
  padding: 16px;
  border-right: 1px solid $grey-60;

  &__menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    :deep(.el-menu) {
      background-color: transparent;
      border-right: 0;
    }

    .el-sub-menu {
      border-radius: 4px;

      .icon {
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        font-family: $secondary-font;
      }

      * {
        vertical-align: middle;
      }

      .el-menu {
        .el-menu-item {
          margin-left: 24px;
        }
      }
    }

    .el-menu-item {
      height: 48px;
      padding-right: 12px !important;
      padding-left: 12px !important;
      border-radius: 4px;

      .icon {
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        font-family: $secondary-font;
      }

      * {
        vertical-align: middle;
      }

      &.is-active {
        color: $primary;
      }
    }

    .separator {
      display: block;
      margin-top: 24px;
      margin-bottom: 12px;
      padding: 0 4px;

      &__title {
        font-size: 12px;
        font-weight: 700;
        font-family: $secondary-font;
      }
    }
  }
}
</style>
