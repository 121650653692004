// Router
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";

// Store
import store from "@/store/index";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getUser.role) {
    case "super-administrator":
      next({ name: "AdministrationSuperAdministratorDashboardIndex" });
      break;
    case "administrator":
      next({ name: "AdministrationAdministratorDashboardIndex" });
      break;
    case "partner":
      next({ name: "AdministrationPartnerDashboardIndex" });
      break;
    default:
      store.dispatch("setLogout").then((): void => {
        next({ name: "HomeIndex" });
      });
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    next();
  } else if (to.meta.protectedRoute && store.getters.getAuthentication.token === "") {
    next({ name: "HomeIndex" });
  } else if (!to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery/change-password/:token",
    name: "AuthenticationPasswordRecoveryChangePasswordIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-change-password-index" */ "../views/authentication/password-recovery/change-password/index.vue"),
    beforeEnter: routerGuard,
  },


  {
    path: "/administration/administrator/dashboard",
    name: "AdministrationAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-dashboard-index" */ "../views/administration/administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/orders",
    name: "AdministrationAdministratorShopOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-orders-index" */ "../views/administration/administrator/shop/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/orders/:order_id/view",
    name: "AdministrationAdministratorShopOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-orders-view-index" */ "../views/administration/administrator/shop/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/orders/:order_id/view",
    name: "AdministrationAdministratorShopOrdersOrderProductsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-orders-order-products-index" */ "../views/administration/administrator/shop/orders/order-products/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists",
    name: "AdministrationAdministratorShopArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-index" */ "../views/administration/administrator/shop/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists/:artist_id/view",
    name: "AdministrationAdministratorShopArtistsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-view-index" */ "../views/administration/administrator/shop/artists/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists/:artist_id/artist-photos",
    name: "AdministrationAdministratorShopArtistsArtistPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-artist-photos-index" */ "../views/administration/administrator/shop/artists/artist-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists/:artist_id/artist-versions",
    name: "AdministrationAdministratorShopArtistsArtistVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-artist-versions-index" */ "../views/administration/administrator/shop/artists/artist-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists/:artist_id/events",
    name: "AdministrationAdministratorShopArtistsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-events-index" */ "../views/administration/administrator/shop/artists/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists/:artist_id/merch",
    name: "AdministrationAdministratorShopArtistsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-merch-index" */ "../views/administration/administrator/shop/artists/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/artists/:artist_id/library",
    name: "AdministrationAdministratorShopArtistsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-artists-library-index" */ "../views/administration/administrator/shop/artists/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/events",
    name: "AdministrationAdministratorShopEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-events-index" */ "../views/administration/administrator/shop/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/events/:product_id/view",
    name: "AdministrationAdministratorShopEventsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-events-view-index" */ "../views/administration/administrator/shop/events/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/events/:product_id/product-photos",
    name: "AdministrationAdministratorShopEventsProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-events-product-photos-index" */ "../views/administration/administrator/shop/events/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/events/:product_id/product-versions",
    name: "AdministrationAdministratorShopEventsProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-events-product-versions-index" */ "../views/administration/administrator/shop/events/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/events/:product_id/product-price-dates",
    name: "AdministrationAdministratorShopEventsProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-events-product-price-dates-index" */ "../views/administration/administrator/shop/events/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/events/:product_id/product-watchers",
    name: "AdministrationAdministratorShopEventsProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-events-product-watchers-index" */ "../views/administration/administrator/shop/events/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/merch",
    name: "AdministrationAdministratorShopMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-merch-index" */ "../views/administration/administrator/shop/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/merch/:product_id/view",
    name: "AdministrationAdministratorShopMerchViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-merch-view-index" */ "../views/administration/administrator/shop/merch/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/merch/:product_id/product-photos",
    name: "AdministrationAdministratorShopMerchProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-merch-product-photos-index" */ "../views/administration/administrator/shop/merch/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/merch/:product_id/product-versions",
    name: "AdministrationAdministratorShopMerchProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-merch-product-versions-index" */ "../views/administration/administrator/shop/merch/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/merch/:product_id/product-price-dates",
    name: "AdministrationAdministratorShopMerchProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-merch-product-price-dates-index" */ "../views/administration/administrator/shop/merch/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/merch/:product_id/product-watchers",
    name: "AdministrationAdministratorShopMerchProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-merch-product-watchers-index" */ "../views/administration/administrator/shop/merch/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/library",
    name: "AdministrationAdministratorShopLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-library-index" */ "../views/administration/administrator/shop/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/library/:product_id/view",
    name: "AdministrationAdministratorShopLibraryViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-library-view-index" */ "../views/administration/administrator/shop/library/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/library/:product_id/product-photos",
    name: "AdministrationAdministratorShopLibraryProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-library-product-photos-index" */ "../views/administration/administrator/shop/library/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/library/:product_id/product-versions",
    name: "AdministrationAdministratorShopLibraryProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-library-product-versions-index" */ "../views/administration/administrator/shop/library/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/library/:product_id/product-price-dates",
    name: "AdministrationAdministratorShopLibraryProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-library-product-price-dates-index" */ "../views/administration/administrator/shop/library/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/library/:product_id/product-watchers",
    name: "AdministrationAdministratorShopLibraryProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-library-product-watchers-index" */ "../views/administration/administrator/shop/library/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/approvals/artists",
    name: "AdministrationAdministratorApprovalsArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-approvals-artists-index" */ "../views/administration/administrator/approvals/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/approvals/events",
    name: "AdministrationAdministratorApprovalsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-approvals-events-index" */ "../views/administration/administrator/approvals/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/approvals/merch",
    name: "AdministrationAdministratorApprovalsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-approvals-merch-index" */ "../views/administration/administrator/approvals/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/approvals/library",
    name: "AdministrationAdministratorApprovalsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-approvals-library-index" */ "../views/administration/administrator/approvals/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/videos",
    name: "AdministrationAdministratorVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-videos-index" */ "../views/administration/administrator/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/newsletters",
    name: "AdministrationAdministratorNewslettersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-newsletters-index" */ "../views/administration/administrator/newsletters/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/dashboard",
    name: "AdministrationPartnerDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-dashboard-index" */ "../views/administration/partner/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/orders",
    name: "AdministrationPartnerShopOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-orders-index" */ "../views/administration/partner/shop/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/orders/:order_id/view",
    name: "AdministrationPartnerShopOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-orders-view-index" */ "../views/administration/partner/shop/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/orders/:order_id/view",
    name: "AdministrationPartnerShopOrdersOrderProductsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-orders-order-products-index" */ "../views/administration/partner/shop/orders/order-products/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists",
    name: "AdministrationPartnerShopArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-index" */ "../views/administration/partner/shop/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/view",
    name: "AdministrationPartnerShopArtistsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-view-index" */ "../views/administration/partner/shop/artists/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/artist-photos",
    name: "AdministrationPartnerShopArtistsArtistPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-artist-photos-index" */ "../views/administration/partner/shop/artists/artist-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/artist-versions",
    name: "AdministrationPartnerShopArtistsArtistVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-artist-versions-index" */ "../views/administration/partner/shop/artists/artist-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/events",
    name: "AdministrationPartnerShopArtistsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-events-index" */ "../views/administration/partner/shop/artists/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/merch",
    name: "AdministrationPartnerShopArtistsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-merch-index" */ "../views/administration/partner/shop/artists/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/library",
    name: "AdministrationPartnerShopArtistsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-library-index" */ "../views/administration/partner/shop/artists/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events",
    name: "AdministrationPartnerShopEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-index" */ "../views/administration/partner/shop/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events/:product_id/view",
    name: "AdministrationPartnerShopEventsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-view-index" */ "../views/administration/partner/shop/events/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events/:product_id/product-photos",
    name: "AdministrationPartnerShopEventsProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-product-photos-index" */ "../views/administration/partner/shop/events/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events/:product_id/product-versions",
    name: "AdministrationPartnerShopEventsProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-product-versions-index" */ "../views/administration/partner/shop/events/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events/:product_id/product-price-dates",
    name: "AdministrationPartnerShopEventsProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-product-price-dates-index" */ "../views/administration/partner/shop/events/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events/:product_id/product-watchers",
    name: "AdministrationPartnerShopEventsProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-product-watchers-index" */ "../views/administration/partner/shop/events/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch",
    name: "AdministrationPartnerShopMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-index" */ "../views/administration/partner/shop/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch/:product_id/view",
    name: "AdministrationPartnerShopMerchViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-view-index" */ "../views/administration/partner/shop/merch/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch/:product_id/product-photos",
    name: "AdministrationPartnerShopMerchProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-product-photos-index" */ "../views/administration/partner/shop/merch/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch/:product_id/product-versions",
    name: "AdministrationPartnerShopMerchProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-product-versions-index" */ "../views/administration/partner/shop/merch/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch/:product_id/product-price-dates",
    name: "AdministrationPartnerShopMerchProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-product-price-dates-index" */ "../views/administration/partner/shop/merch/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch/:product_id/product-watchers",
    name: "AdministrationPartnerShopMerchProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-product-watchers-index" */ "../views/administration/partner/shop/merch/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library",
    name: "AdministrationPartnerShopLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-index" */ "../views/administration/partner/shop/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library/:product_id/view",
    name: "AdministrationPartnerShopLibraryViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-view-index" */ "../views/administration/partner/shop/library/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library/:product_id/product-photos",
    name: "AdministrationPartnerShopLibraryProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-product-photos-index" */ "../views/administration/partner/shop/library/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library/:product_id/product-versions",
    name: "AdministrationPartnerShopLibraryProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-product-versions-index" */ "../views/administration/partner/shop/library/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library/:product_id/product-price-dates",
    name: "AdministrationPartnerShopLibraryProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-product-price-dates-index" */ "../views/administration/partner/shop/library/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library/:product_id/product-watchers",
    name: "AdministrationPartnerShopLibraryProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-product-watchers-index" */ "../views/administration/partner/shop/library/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/artists",
    name: "AdministrationPartnerApprovalsArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-artists-index" */ "../views/administration/partner/approvals/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/events",
    name: "AdministrationPartnerApprovalsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-events-index" */ "../views/administration/partner/approvals/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/merch",
    name: "AdministrationPartnerApprovalsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-merch-index" */ "../views/administration/partner/approvals/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/library",
    name: "AdministrationPartnerApprovalsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-library-index" */ "../views/administration/partner/approvals/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/dashboard",
    name: "AdministrationPartnerDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-dashboard-index" */ "../views/administration/partner/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/orders",
    name: "AdministrationPartnerShopOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-orders-index" */ "../views/administration/partner/shop/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/orders/:order_id/view",
    name: "AdministrationPartnerShopOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-orders-view-index" */ "../views/administration/partner/shop/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists",
    name: "AdministrationPartnerShopArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-index" */ "../views/administration/partner/shop/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/view",
    name: "AdministrationPartnerShopArtistsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-view-index" */ "../views/administration/partner/shop/artists/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/artist-photos",
    name: "AdministrationPartnerShopArtistsArtistPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-artist-photos-index" */ "../views/administration/partner/shop/artists/artist-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/events",
    name: "AdministrationPartnerShopArtistsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-events-index" */ "../views/administration/partner/shop/artists/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/merch",
    name: "AdministrationPartnerShopArtistsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-merch-index" */ "../views/administration/partner/shop/artists/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/artists/:artist_id/library",
    name: "AdministrationPartnerShopArtistsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-artists-library-index" */ "../views/administration/partner/shop/artists/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events",
    name: "AdministrationPartnerShopEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-index" */ "../views/administration/partner/shop/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/events/:product_id/view",
    name: "AdministrationPartnerShopEventsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-events-view-index" */ "../views/administration/partner/shop/events/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch",
    name: "AdministrationPartnerShopMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-index" */ "../views/administration/partner/shop/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/merch/:product_id/view",
    name: "AdministrationPartnerShopMerchViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-merch-view-index" */ "../views/administration/partner/shop/merch/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library",
    name: "AdministrationPartnerShopLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-index" */ "../views/administration/partner/shop/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/shop/library/:product_id/view",
    name: "AdministrationPartnerShopLibraryViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-shop-library-view-index" */ "../views/administration/partner/shop/library/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/artists",
    name: "AdministrationPartnerApprovalsArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-artists-index" */ "../views/administration/partner/approvals/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/events",
    name: "AdministrationPartnerApprovalsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-events-index" */ "../views/administration/partner/approvals/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/merch",
    name: "AdministrationPartnerApprovalsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-merch-index" */ "../views/administration/partner/approvals/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/partner/approvals/library",
    name: "AdministrationPartnerApprovalsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-partner-approvals-library-index" */ "../views/administration/partner/approvals/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/dashboard",
    name: "AdministrationSuperAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-dashboard-index" */ "../views/administration/super-administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/users",
    name: "AdministrationSuperAdministratorUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-users-index" */ "../views/administration/super-administrator/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/newsletters",
    name: "AdministrationSuperAdministratorNewslettersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-newsletters-index" */ "../views/administration/super-administrator/newsletters/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/videos",
    name: "AdministrationSuperAdministratorVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-videos-index" */ "../views/administration/super-administrator/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/promotions",
    name: "AdministrationSuperAdministratorPromotionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-promotions-index" */ "../views/administration/super-administrator/promotions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/orders",
    name: "AdministrationSuperAdministratorShopOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-orders-index" */ "../views/administration/super-administrator/shop/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/orders/:order_id/view",
    name: "AdministrationSuperAdministratorShopOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-orders-view-index" */ "../views/administration/super-administrator/shop/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/orders/:order_id/view",
    name: "AdministrationSuperAdministratorShopOrdersOrderProductsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-orders-order-products-index" */ "../views/administration/super-administrator/shop/orders/order-products/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists",
    name: "AdministrationSuperAdministratorShopArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-index" */ "../views/administration/super-administrator/shop/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists/:artist_id/view",
    name: "AdministrationSuperAdministratorShopArtistsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-view-index" */ "../views/administration/super-administrator/shop/artists/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists/:artist_id/artist-photos",
    name: "AdministrationSuperAdministratorShopArtistsArtistPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-artist-photos-index" */ "../views/administration/super-administrator/shop/artists/artist-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists/:artist_id/artist-versions",
    name: "AdministrationSuperAdministratorShopArtistsArtistVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-artist-versions-index" */ "../views/administration/super-administrator/shop/artists/artist-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists/:artist_id/events",
    name: "AdministrationSuperAdministratorShopArtistsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-events-index" */ "../views/administration/super-administrator/shop/artists/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists/:artist_id/merch",
    name: "AdministrationSuperAdministratorShopArtistsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-merch-index" */ "../views/administration/super-administrator/shop/artists/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/artists/:artist_id/library",
    name: "AdministrationSuperAdministratorShopArtistsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-artists-library-index" */ "../views/administration/super-administrator/shop/artists/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/events",
    name: "AdministrationSuperAdministratorShopEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-events-index" */ "../views/administration/super-administrator/shop/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/events/:product_id/view",
    name: "AdministrationSuperAdministratorShopEventsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-events-view-index" */ "../views/administration/super-administrator/shop/events/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/events/:product_id/product-photos",
    name: "AdministrationSuperAdministratorShopEventsProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-events-product-photos-index" */ "../views/administration/super-administrator/shop/events/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/events/:product_id/product-versions",
    name: "AdministrationSuperAdministratorShopEventsProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-events-product-versions-index" */ "../views/administration/super-administrator/shop/events/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/events/:product_id/product-price-dates",
    name: "AdministrationSuperAdministratorShopEventsProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-events-product-price-dates-index" */ "../views/administration/super-administrator/shop/events/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/events/:product_id/product-watchers",
    name: "AdministrationSuperAdministratorShopEventsProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-events-product-watchers-index" */ "../views/administration/super-administrator/shop/events/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/merch",
    name: "AdministrationSuperAdministratorShopMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-merch-index" */ "../views/administration/super-administrator/shop/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/merch/:product_id/view",
    name: "AdministrationSuperAdministratorShopMerchViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-merch-view-index" */ "../views/administration/super-administrator/shop/merch/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/merch/:product_id/product-photos",
    name: "AdministrationSuperAdministratorShopMerchProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-merch-product-photos-index" */ "../views/administration/super-administrator/shop/merch/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/merch/:product_id/product-versions",
    name: "AdministrationSuperAdministratorShopMerchProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-merch-product-versions-index" */ "../views/administration/super-administrator/shop/merch/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/merch/:product_id/product-price-dates",
    name: "AdministrationSuperAdministratorShopMerchProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-merch-product-price-dates-index" */ "../views/administration/super-administrator/shop/merch/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/merch/:product_id/product-watchers",
    name: "AdministrationSuperAdministratorShopMerchProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-merch-product-watchers-index" */ "../views/administration/super-administrator/shop/merch/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/library",
    name: "AdministrationSuperAdministratorShopLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-library-index" */ "../views/administration/super-administrator/shop/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/library/:product_id/view",
    name: "AdministrationSuperAdministratorShopLibraryViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-library-view-index" */ "../views/administration/super-administrator/shop/library/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/library/:product_id/product-photos",
    name: "AdministrationSuperAdministratorShopLibraryProductPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-library-product-photos-index" */ "../views/administration/super-administrator/shop/library/product-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/library/:product_id/product-versions",
    name: "AdministrationSuperAdministratorShopLibraryProductVersionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-library-product-versions-index" */ "../views/administration/super-administrator/shop/library/product-versions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/library/:product_id/product-price-dates",
    name: "AdministrationSuperAdministratorShopLibraryProductPriceDatesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-library-product-price-dates-index" */ "../views/administration/super-administrator/shop/library/product-price-dates/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/library/:product_id/product-watchers",
    name: "AdministrationSuperAdministratorShopLibraryProductWatchersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-library-product-watchers-index" */ "../views/administration/super-administrator/shop/library/product-watchers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/coupons",
    name: "AdministrationSuperAdministratorShopCouponsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-coupons-index" */ "../views/administration/super-administrator/shop/coupons/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/approvals/artists",
    name: "AdministrationSuperAdministratorApprovalsArtistsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-approvals-artists-index" */ "../views/administration/super-administrator/approvals/artists/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/approvals/events",
    name: "AdministrationSuperAdministratorApprovalsEventsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-approvals-events-index" */ "../views/administration/super-administrator/approvals/events/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/approvals/merch",
    name: "AdministrationSuperAdministratorApprovalsMerchIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-approvals-merch-index" */ "../views/administration/super-administrator/approvals/merch/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/approvals/library",
    name: "AdministrationSuperAdministratorApprovalsLibraryIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-approvals-library-index" */ "../views/administration/super-administrator/approvals/library/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/currencies",
    name: "AdministrationSuperAdministratorSettingsCurrenciesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-currencies-index" */ "../views/administration/super-administrator/settings/currencies/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/product-types",
    name: "AdministrationSuperAdministratorSettingsProductTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-product-types-index" */ "../views/administration/super-administrator/settings/product-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/product-categories",
    name: "AdministrationSuperAdministratorSettingsProductCategoriesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-product-categories-index" */ "../views/administration/super-administrator/settings/product-categories/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
